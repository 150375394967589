import Button from "@cloudscape-design/components/button";
import { useState, useEffect} from 'react';

import { addLike } from "../utils/API";

let LikeButton = (props) => {
    //console.log(props.disabled)
    const [ isDisabled, setIsDisabled ] = useState(props.disabled)

    useEffect(() => {
        //console.log(props.disabled)
        setIsDisabled(props.disabled)

    }, [props.disabled])


    //console.log(isDisabled)
    return (
        <div>
            <Button
                iconName={props?.icon}
                variant="icon"
                disabled={isDisabled}
                onClick={() => {
                    console.log(props)
                    addLike({"id": props.id, "createdDate": props?.['createdDate'], "alias": props.alias}).then(
                        (result) => {
                            //console.log(result)
                            setIsDisabled(true)
                        },
                        (error) => {
                            console.log(error)
                        }
                    )
                }}
            >
                
            </Button>
            {props.count} Like(s)
        </div>
    )
}

export default LikeButton;