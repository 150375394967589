import { applyMode, Mode } from '@cloudscape-design/global-styles';
import AppLayout from '@cloudscape-design/components/app-layout';
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Flashbar from "@cloudscape-design/components/flashbar";
import Button from "@cloudscape-design/components/button";
import CSLink from "@cloudscape-design/components/link";
import { useNavigate, Link } from 'react-router-dom';

import { useState, useEffect, Fragment} from 'react';

import ItemsView from './components/ItemsView';
import EditItem from './pages/EditItem';
import ItemPage from './pages/ItemPage';

import { BrowserRouter, BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';

//Local Files
import awsLogo from './assets/AWS_logo_RGB_WHT.png';

//note: redirects if not authenticated
import getMidwayToken from "./utils/MidwayToken";

console.log("***VERSION***")
console.log("0.0.1")
const midwayToken = getMidwayToken();
//console.log(midwayToken)
let alias = '';
if (midwayToken && midwayToken.sub) {
  alias = midwayToken.sub;
  console.log(alias)
  sessionStorage.setItem("alias", alias)
}
//alias = 'skarra';
//sessionStorage.setItem("alias", alias)


let App = () => {
  //Cloudscape Darkmode
  //applyMode(Mode.Dark);

  const [ flashbarItems, setFlashbarItems ] = useState([])

  return (
    <div className="App">
     <div style={{
          paddingLeft: "0%", 
          paddingTop: "1%", 
          paddingBottom: "3%", 
          background: "linear-gradient(to left, #621346, #461869, #2058BB, #1F2F46)",
          color: 'white',
          fontWeight: 'bold',
          fontSize: "2em",
          textAlign: 'center'
        }}>
          <img 
            src={awsLogo} 
            width={"50px"} 
            style={{
              maxWidth: '100px', // Set a maximum width for the image to make it small
              maxHeight: '100px', // Set a maximum height for the image
              display: 'block', // Display image as a block to enable centering via text-align on container
              margin: '0 auto', // Center the image horizontally within the container
            }}
          />
          <SpaceBetween
            direction='vertical'
            size='s'
          >
            <span>< span style={{color: "white"}}>Inspire</span> | Architecture Repository</span>
            <span style={{ fontFamily: 'Georgia, serif', color: '#dcd1e3', fontSize: '15px', fontStyle: 'italic', borderLeft: '4px solid #fde230', paddingLeft: '10px', marginLeft: '10px' }}>
            "Collaboration <em style={{textDecoration: "none", color: "#fde230"}}>inspires</em> turning the impossible into opportunity."
            </span>
            </SpaceBetween>
        </div>
      <AppLayout
          toolsHide
          navigationHide={true}
          notifications={<Flashbar items={flashbarItems} />}
          content={
            <div>

            <Router>
              <Routes>
                <Route path="/" element={<ItemsView alias={alias}/>}/>
                <Route path="/new" element={<EditItem alias={alias} setFlashbarItems={setFlashbarItems}/>}/>
                <Route path="/item/:id/:createdDate" element={<ItemPage alias={alias}/>}/>
              </Routes>
            </Router>
          </div>
          } 

          >
          
      </AppLayout>
    </div>
  );
}

export default App;
