import './Comments.css'

import { addComment } from '../utils/API';

import { 
    Container, 
    Header,
    SpaceBetween,
    Textarea,
    Button 
} from "@cloudscape-design/components";

import { useState, useEffect} from 'react';

import { getItem } from "../utils/API";

let Comments = (props) => {
    const [ newComment, setNewComment ] = useState("")
    const [ isCommentSubmitDisabled, setIsCommentSubmitDisabled ] = useState(false)

    return (
        <div>
            <Container
                header={
                    <Header
                        variant="h2"
                        description=""
                    >
                        Comments
                    </Header>
                }
            >
                <SpaceBetween size="m">
                    <div className="new-comment-area">
                        <Textarea 
                            onChange={({ detail }) => setNewComment(detail.value)}
                            value={newComment}
                            placeholder="Add a new comment"
                        />
                        <Button 
                            disabled ={isCommentSubmitDisabled}
                            onClick={() => {
                                if(newComment != ""){
                                    setIsCommentSubmitDisabled(true)
                                    let commentObj = {
                                        "id": props.id,
                                        "createdDate": props?.['createdDate'],
                                        "comment": {
                                            "alias": props.alias,
                                            "comment_text": newComment
                                        }
                                    }
                                    //console.log(commentObj)
                                    setNewComment("")
                                    addComment(commentObj).then(
                                        (result) => {
                                            setIsCommentSubmitDisabled(false)
                                            //console.log(result)
                                            window.location.reload();
                                        },
                                        (error) =>{
                                            setIsCommentSubmitDisabled(false)
                                            console.log(error)
                                        }
                                    )
                                }
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                    {
                        props.data?.map(comment => (
                            <Comment key={comment?.timestamp} comment ={comment}/>
                        ))
                    }

                </SpaceBetween>
                
            </Container>
        </div>
    )

}


export default Comments;



let Comment = (props) => {
    return (
        <div className='existing-comment-box'>
            <div className="user-details">
                <div className="profile-photo">
                    <img src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + props.comment.alias} height='64px'/>
                </div>
                <div className="comment-info">
                    <div className="user-name">
                        <a href={'https://phonetool.amazon.com/users/' + props.comment.alias} target="_blank">{props.comment.alias}</a>
                    </div>
                    <div className="timestamp">
                        {new Date(parseInt(props?.comment?.timestamp)*1000).toString()}
                    </div>
                </div>

            </div>
            <div className="comment-text">
                {props.comment.comment_text}
            </div>
        </div>
    )
}