import Cards from "@cloudscape-design/components/cards";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter"
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import RadioGroup from "@cloudscape-design/components/radio-group"
import { 
    Checkbox,
    Multiselect,
    SpaceBetween
} from "@cloudscape-design/components";

import LikeButton from "./LikeButton";
import FilterCheckbox from "./FilterCheckbox";

import './ItemsView.css'
import { useState, useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getItems, getTags } from "../utils/API";
import constants from "../utils/Constants";
import { getServices } from "../utils/AWSServicesList";

let ItemsView = (props) => {

    const [ selectedItems, setSelectedItems  ] = useState([]);
    const [ value, setValue ] = useState("second");
    const [ items, setItems ] = useState([])
    const [ currentPageIndex, setCurrentPageIndex ] = useState(1)
    const [ paginatedFilteredItems, setPaginatedFilteredItems ] = useState([])
    const [ filteredItems, setFilteredItems ] = useState([])
    const [ selectedServiceFilter, setSelectedServiceFilter ] = useState([])
    const [ selectedTagFilter, setSelectedTagFilter ] = useState([])
    const [ tags, setTags ] = useState([])

    const [selectedImage, setSelectedImage] = useState(null);

    const openModal = (imageUrl ) => {
        setSelectedImage(imageUrl);
      };
    
      const closeModal = () => {
        setSelectedImage(null);
      };

    let services = getServices();

    const navigate = useNavigate()

    useEffect(() =>{
         getItems().then(
            (result) => {
                //console.log(result)
                result = result['Items']
                setItems(result)
                setFilteredItems(result)
                setPaginatedFilteredItems(result.slice(0,6))
            },
            (error) => {
                console.log(error)
            }
        ) 
        getTags().then(
            (result) => {
                //console.log(result)
                //console.log(typeof result)
                let tagsList = [];
                if(result){
                  result.forEach((x, i) => {
                    tagsList.push({"label": x?.['tag'], "value": x?.['tag']})
                  })
                }
                //console.log(tagsList)
                setTags(tagsList)
            }, 
            (error) => {
                console.log(error)
            }
        )
    }, [])

    let getTagsOld = (items) => {
        let tagsArr = []
        //console.log(items)
        items.forEach((i) => {
            //console.log(i)
            i?.['tags']?.forEach((t) =>{
                //console.log(t)
                //console.log(tagsArr)
                if(!tagsArr.some(e => e.label === t)) {
                    tagsArr.push({label: t, value: t})
                }
            })
            
        })
        //console.log(tagsArr)
        setTags(tagsArr)
    }

    let filterItems = (tags, services) => {
        let filteredItems = []
        //Add items by tag
        if(tags.length !=0 || services.length !=0){
            tags.forEach((tag) => {
                items.forEach((item) => {
                    //console.log(tag)
                    //console.log(item['_source']?.['tags'])
                    if(item?.['tags']?.includes(tag?.['value'])){
                        if(filteredItems.indexOf(item) === -1){
                            filteredItems.push(item)
                        }
                    }
                })
            })
            services.forEach((service) => {
                items.forEach((item) => {
                    //console.log(service)
                    //console.log(item['_source']?.['services'])
                    if(item?.['services']?.includes(service?.['value'])){
                        if(filteredItems.indexOf(item) === -1){
                            filteredItems.push(item)
                        }
                    }
                })
            })
            //console.log("filtered results")
            //console.log(filteredItems)
            setPaginatedFilteredItems(filteredItems.slice(0,6))
            setFilteredItems(filteredItems)
        } else {
            //console.log("No Filters Selected");
            setFilteredItems(items)
            setPaginatedFilteredItems(items.slice(0,6))
        }
    }

    return (
        <div className="grid-container">
            <div className="grid-item">
                
            
            <Cards
                onSelectionChange={({ detail }) =>
                    setSelectedItems(detail.selectedItems)
                }
                selectedItems={selectedItems}
                ariaLabels={{
                    itemSelectionLabel: (e, t) => `select ${t.name}`,
                    selectionGroupLabel: "Item selection"
                }}
                cardDefinition={{
                    header: e => <Link to={"item/" + e?.id + "/" + e?.createdDate}>{e?.title}</Link>,
                    sections: [
                    {
                        id: "id",
                        header: "id",
                        content: e => e.id
                    },
                    {
                        id: "description",
                        header: "Description",
                        content: e => e?.description
                    },

                    {
                        id: "services",
                        header: "Services",
                        content: e => e?.services?.join(', ')
                    },
                    {
                        id: "tags",
                        header: "Tags",
                        content: e => e?.tags?.join(', ')
                    },
                    {
                        id: "thumbnail",
                        header: "",
                        content: e => <img width="250" src={e?.['architecture-diagram']?.replace('s3://inspire-static-content/', constants.staticContentUlrPrefix)} onClick={() => openModal(e?.['architecture-diagram']?.replace('s3://inspire-static-content/', constants.staticContentUlrPrefix))} style={{cursor: "pointer"}}/>
                    },
                    {
                        id: "like",
                        header: "",
                        content: e => <div style={{display: "flex", flexDirection: "row"}}> <LikeButton id={e?.id} alias={props.alias} createdDate={e?.['createdDate']} disabled={e?.['likes']?.includes(props.alias)} count={e?.['likes']?.length} icon={e?.['likes']?.includes(props.alias)? "thumbs-up-filled" : "thumbs-up"}/> <Button iconName="contact" variant="icon" style={{paddingLeft: "20px"}} onClick={() => navigate('/item/' +e?.id + "/" + e?.createdDate) }/> </div>
                    },
                    ]
                }}
                cardsPerRow={[
                    { cards: 1 },
                    { minWidth: 500, cards: 3 }
                ]}
                items={paginatedFilteredItems}
                loadingText="Loading resources"
                trackBy="id"
                visibleSections={["description", "thumbnail", "services","tags","like", "comment"]}
                filter={
                    <div className="filters">
                        <SpaceBetween size="s">
                            <Multiselect
                                filteringType="auto"      
                                placeholder="Filter by Service"
                                selectedOptions={selectedServiceFilter} 
                                options={services}
                                onChange={
                                    ({ detail }) => {
                                        setSelectedServiceFilter(detail.selectedOptions)
                                        filterItems(selectedTagFilter, detail.selectedOptions)
                                    }
                                }
                            />
                            <Multiselect
                                filteringType="auto"      
                                placeholder="Filter by Tag"
                                selectedOptions={selectedTagFilter} 
                                options={tags}
                                onChange={
                                    ({ detail }) => {
                                        setSelectedTagFilter(detail.selectedOptions)
                                        filterItems(detail.selectedOptions, selectedServiceFilter)
                                    }
                                }
                            />
                        </SpaceBetween>
                    </div>
                }
                header={
                    <Header
                    actions={
                        <SpaceBetween
                            direction="horizontal"
                            size="s"
                        >
                            <Button 
                                variant="primary"
                                onClick={(event) => {
                                    navigate("/new");
                                }}
                            >
                                Add New
                            </Button>
                        </SpaceBetween>
                    }
                    >
                    Published Architectures
                    </Header>
                }
                
                pagination={
                    <Pagination 
                        currentPageIndex={currentPageIndex} 
                        onChange={({ detail }) => {
                            setCurrentPageIndex(detail.currentPageIndex)
                           //console.log(detail.currentPageIndex)
                            let itemsStartIndex = 6*(detail.currentPageIndex-1)
                            setPaginatedFilteredItems(filteredItems.slice(itemsStartIndex, itemsStartIndex+6))
                        }}
                        pagesCount={Math.ceil(filteredItems.length / 6)} 
                    />
                }
            />
            </div>
            {selectedImage && (
                <div className="modal" onClick={closeModal}>
                <img src={selectedImage} alt="Selected" className="modal-content" />
                <span className="close-button">&times;</span>
                </div>
            )}

        </div>
    )
}

export default ItemsView