
let SERVICE_URL = 'https://ufe3j8vzdb.execute-api.us-east-1.amazonaws.com/prod/'

async function internalFetch(fetchUrl) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      //console.log(fetchUrl)
      fetch(fetchUrl, {
          headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("midway_id_token")
          },
        })
        .then(
          res => res.json(),
          error => {
            console.log("fetch", error);
            reject(error);
          }
        )
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else if (result && result.body) {
              resolve(result.body);
            }
            else {
              resolve(result);
            }
          },
          (error) => {
            console.log("fetch", error);
            reject(error);
          }
        );
    });
  }

async function getItems() {
    let fetchUrl = SERVICE_URL + "item";
    return internalFetch(fetchUrl);
}

async function getTags() {
  let fetchUrl = SERVICE_URL + "tags";
  return internalFetch(fetchUrl);
  
}

async function getItem(id, createdDate) {
  let fetchUrl = SERVICE_URL + "item?id=" + id +"&createdDate=" + createdDate;
  return internalFetch(fetchUrl);
}

async function getDiagram(id, createdDate) {
  let fetchUrl = SERVICE_URL + "item/diagram?id=" + id +"&createdDate=" + createdDate;
  return internalFetch(fetchUrl);
}

async function getSource(id, createdDate) {
  let fetchUrl = SERVICE_URL + "item/source?id=" + id +"&createdDate=" + createdDate;
  return internalFetch(fetchUrl);
}

async function getUploadUrl(uuid, fileName) {
  let fetchUrl = SERVICE_URL + "item/diagram/upload-url?uuid=" + uuid + "&fileName=" + fileName
  return internalFetch(fetchUrl);
}

async function addItem(item) {
  let fetchUrl = SERVICE_URL + "item";
  //console.log("*******")
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    /* global fetch */
    fetch(fetchUrl, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item)
      })
      .then(res => res.json())
      .then(
        (result) => {
          if (result && result.errorMessage) {
            reject(result.errorMessage);
          }
          else {
            resolve(result);
            //console.log(result)
          }
        },
        (error) => {
          console.log("saveItem", error);
          reject(error);
        }
      );
  });
}

async function uploadFile(url, fields, file ) {
  let fetchUrl = url;
  return new Promise((resolve, reject) => {

    let formData = new FormData()
    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key]);
    });
    formData.append("file", file)
    //console.log(formData)
    fetch(fetchUrl, {
        method: 'POST',
        body: formData
      })
      .then(
        (result) => {
          if (result && result.errorMessage) {
            reject(result.errorMessage);
          }
          else {
            resolve(result);
            //console.log(result)
          }
        },
        (error) => {
          console.log("uploadFile", error);
          reject(error);
        }
      );
  });
}

async function deleteItem(id, createdDate) {
  let fetchUrl = SERVICE_URL + "item?id=" + id + "&createdDate=" + createdDate;
  return new Promise((resolve, reject) => {

    fetch(fetchUrl, {
        method: 'DELETE'
      })
      .then(
        (result) => {
          if (result && result.errorMessage) {
            reject(result.errorMessage);
          }
          else {
            resolve(result);
            //console.log(result)
          }
        },
        (error) => {
          console.log("delete", error);
          reject(error);
        }
      );
  });
}

async function addComment(commentObj) {
  let fetchUrl = SERVICE_URL + "item/comment";
  //console.log("*******")
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    /* global fetch */
    fetch(fetchUrl, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(commentObj)
      })
      .then(res => res.json())
      .then(
        (result) => {
          if (result && result.errorMessage) {
            reject(result.errorMessage);
          }
          else {
            resolve(result);
            //console.log(result)
          }
        },
        (error) => {
          console.log("addComment", error);
          reject(error);
        }
      );
  });
}

async function addLike(likeObj) {
  let fetchUrl = SERVICE_URL + "item/like";
  //console.log("*******")
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    /* global fetch */
    fetch(fetchUrl, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(likeObj)
      })
      .then(res => res.json())
      .then(
        (result) => {
          if (result && result.errorMessage) {
            reject(result.errorMessage);
          }
          else {
            resolve(result);
            //console.log(result)
          }
        },
        (error) => {
          console.log("addLike", error);
          reject(error);
        }
      );
  });
}

export { 
  getItems, 
  getItem, 
  getDiagram, 
  getSource, 
  addItem, 
  getUploadUrl, 
  uploadFile, 
  deleteItem, 
  addComment, 
  addLike,
  getTags 
}