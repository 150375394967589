import { useNavigate, useLocation} from 'react-router-dom';

import {
    Button,
    Link,
    Container,
    Form,
    FormField,
    Header,
    Input,
    Select,
    SpaceBetween,
    Textarea as TextArea,
    DatePicker,
    Checkbox,
    Table,
    Box,
    Modal,
    RadioGroup,
    Multiselect,
    Icon,
    TokenGroup,
    Tiles,
    AttributeEditor,
    Autosuggest
  }
  from "@cloudscape-design/components";


import React,{ useState, useEffect, useCallback, useMemo} from 'react';

import { addItem, getUploadUrl, uploadFile, getTags } from '../utils/API';

import { getServices } from "../utils/AWSServicesList";


function uuidv4() {
return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
);
}


const i18nStrings = {
    addButtonText: 'Add new item',
    removeButtonText: 'Remove',
    empty: 'No tags associated to the resource',
  };
  const tagLimit = 50;

let EditItem = (props) => {
    const navigate = useNavigate()
    const {state} = useLocation();
    //console.log(state)
    const s3Prefix = 's3://inspire-static-content/'

    let alias = props.alias

    const [ tags, setTags ] = useState([])
    const [ newTag, setNewTag ] = useState("")
    const [ isSubmitDisabled, setIsSubmitDisabled ] = useState(false)

    useEffect(() =>{
        //console.log('running use effect')

        if(alias === '' || alias == null){
            console.log("alias not found");
            navigate('/')
        } else {
            console.log(alias)
        }
        if(state){
            //console.log('setting state as item')
            setItem(state)
        }
        getTags().then(
            (result) => {
                //console.log(result)
                //console.log(typeof result)
                let tagsList = [];
                if(result){
                  result.forEach((x, i) => {
                    tagsList.push({"value": x?.['tag']})
                  })
                }
                //console.log(tagsList)
                setTags(tagsList)
            }, 
            (error) => {
                console.log(error)
            }
        )
    }, [])

    const [items, setItems] = useState([
        { name: "some-key-1", link: "some-value-1" },
        { name: "some-key-2", link: "some-value-2" }
      ]);

    const [item, setItem ] = useState( {
        "id": uuidv4(),
        "createdDate": Math.floor(Date.now() / 1000).toString(),
        "title": "",
        "author": alias,
        "description": "",
        "services": [],
        "tags": [],
        "architecture-diagram": "",
        "architecture-source": "",
        "resource-links": [],
        "comments": [],
        "likes": []
    })

    const [ isEditMode, setIsEditMode ] = useState(state ? true : false)

    let flashbarCreateNotification = {
        type: "info",
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => props.setFlashbarItems([]),
        content: (
          <>
            Archtiecture item is being added. It may take up to 1 minute. Follow this link to view the architecture in a new tab. <br />
            <a href={"https://inspire.sa.aws.dev" + "/item/"+ item['id'] + "/" + item['createdDate']}
              target='_blank'
            >
              {item['id']}
            </a>
            .
          </>
        ),
        id: "message_1"
      }

    const [ diagramFile, setDiagramFile ] = useState()
    const [ sourceFile, setSourceFile ] = useState()
    const [ diagramFileErrorText, setDiagramFileErrorText ] = useState('')
    const [ sourceFileErrorText, setSourceFileErrorText ] = useState('')
    const [ titleErrorText, setTitleErrorText ] = useState('')
    const [ descriptionErrorText, setDescriptionErrorText ] = useState('')
    const [ servicesErrorText, setServicesErrorText ] = useState('')
    const [ authorErrorText, setAuthorErrorText ] = useState('')
    const [ resourceLinksErrorText, setResourceLinksErrorText ] = useState("")
    

    let validateForm = () => {
        let validationResult = true;
        if(item['title'] === ''){
            setTitleErrorText('Please Enter a Title');
            validationResult = false;
        } else {
            setTitleErrorText('')
        }
        if(item['description'] === ''){
            setDescriptionErrorText('Please Enter a Description');
            validationResult = false;
        } else {
            setDescriptionErrorText('')
        }
        if(item['services'].length == 0){
            setServicesErrorText('Please Select at Least One Service');
            validationResult = false;
        } else {
            setServicesErrorText('')
        }
        if(item['architecture-diagram'] === ''){
            setDiagramFileErrorText('Please upload an Architecture Diagram');
            validationResult = false;
        } else {
            setDiagramFileErrorText('')
        }
        if(item['architecture-source'] === ''){
            setSourceFileErrorText('Please upload an Architecture Source File');
            validationResult = false;
        } else {
            setSourceFileErrorText('')
        }
        if(item['author'] === ''){
            setAuthorErrorText('User Not Logged In. Please go to the homepage and refresh.');
            validationResult = false;
        } else {
            setAuthorErrorText('')
        }
        item['resource-links'].forEach(linkObj => {
            if (linkObj.name.trim() !== '' && isValidHttpsUrl(linkObj.link)) {
                setResourceLinksErrorText('')
            } else {
                validationResult = false;
                setResourceLinksErrorText("Invalid url. Must contain a name and start with https://")
            }
        });
        return validationResult;
    }

    let onSubmit = (item) => {
        
        if(validateForm()){
            //console.log("Submiting Item")
            
            //console.log(parsedItem)
            if(true){
                addItem(item).then(
                    (result) => {
                        //console.log(result)
                        props.setFlashbarItems([flashbarCreateNotification])
                        navigate('/')
                    },
                    (error) => {
                        //console.log(error)
                        navigate('/')
                    }
                )
            }
            
            
        } else {
            console.log("Failed Validation")
        }
        
      }

      function isValidHttpsUrl(string) {
        const regex = /^(https:\/\/)[a-zA-Z0-9-\.]+\.[a-zA-Z]{2,}(\/.*)?$/;
        //console.log(regex.test(string))
        return regex.test(string);
    }

      const updateLinkName = (index, newName) => {
        // Create a new array with all links
        const updatedLinks = item['resource-links'].map((link, idx) => {
            if (idx === index) {
                // When the index matches, return a new object with the updated name
                return { ...link, name: newName };
            }
            return link; // Otherwise, return the link as is
        });

        // Set the new state
        setItem({
            ...item,
            ['resource-links']: updatedLinks
        });
    };

    const updateLinkValue = (index, newValue) => {
        // Create a new array with all links
        const updatedLinks = item['resource-links'].map((link, idx) => {
            if (idx === index) {
                // When the index matches, return a new object with the updated name
                return { ...link, link: newValue };
            }
            return link; // Otherwise, return the link as is
        });

        // Set the new state
        setItem({
            ...item,
            ['resource-links']: updatedLinks
        });
    };

    let getSelectObjects = (selectList) => {
        let selectObjectList = []
        if (selectList) {
            selectList.forEach((x, i) => {
                selectObjectList.push({label: x, value: x})
            })
        }
        
        return selectObjectList;
    }

    let  validateTag = (tag) => {
        // Regular expression to check for valid characters (letters, numbers, and hyphens)
        const validTagRegex = /^[a-z0-9-]+$/;
    
        // Check if the tag matches the regular expression
        return validTagRegex.test(tag) || tag === "";
    }
    


    return (
        <div>
            <p style={{color: "red"}}>Important! Do NOT add any proprietary or AWS Business Data to this site. Refer to the <a href="https://policy.a2z.com/docs/97/publication" target="_blank">AWS Data Classification Policy</a> for more details.</p> 
            <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button 
                        variant="normal"
                        onClick={() => {
                            navigate('/')
                        }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="primary" 
                        disabled={isSubmitDisabled}
                        onClick={() => {
                            //console.log(item)
                            onSubmit(item)
                        }}
                    >
                        Submit
                    </Button>
                        
                  </SpaceBetween>
                }
                errorText={""}
              >
    
            <SpaceBetween direction="vertical" size="l">
              
              <Container header={
                  <Header 
                    variant="h2">
                    New Architecture
                  </Header>
              }>
                <SpaceBetween direction="vertical" size="l">
                    <FormField label="Title" description="Title of Architecture" errorText={titleErrorText}>
                        <Input 
                            value={item?.['title']}
                            onChange={({ detail }) =>  {
                                setItem({...item, ['title']: detail.value})
                            }}
                        >

                        </Input>

                    </FormField>
                    <FormField label="Author" description="Author" errorText={authorErrorText}>
                        <Input 
                            value={item?.['author']}
                            disabled
                        >

                        </Input>

                    </FormField>
                    <FormField label="Description" description="Description" errorText={descriptionErrorText}>
                        <TextArea 
                            value={item?.['description']}
                            onChange={({ detail }) => {
                                setItem({...item, ['description']: detail.value})
                            }}
                        >

                        </TextArea>

                    </FormField>
                    <FormField label="Services" description="Services" errorText={servicesErrorText}>
                        <Multiselect
                        filteringType="auto"
                            options={getServices()}
                            selectedOptions={getSelectObjects(item?.['services'])}
                            onChange={({ detail }) => {
                                let serviceNames = [];
                                if (detail.selectedOptions) {
                                    detail.selectedOptions.forEach((x, i) => {
                                        serviceNames.push(x?.value)
                                })
                            }
                                setItem({...item, ['services']: serviceNames})
                            }}
                        ></Multiselect>

                    </FormField>
                    <FormField label="Tags" description="Select tag(s) for the architecture" errorText={""}>
                        <SpaceBetween
                            direction='vertical'
                            size='s'
                        >
                            <Multiselect
                                options={tags}
                                filteringType='auto'
                                selectedOptions={getSelectObjects(item?.['tags'])}
                                onChange={({ detail }) => {
                                    let tagNames = [];
                                    if (detail.selectedOptions) {
                                        detail.selectedOptions.forEach((x, i) => {
                                            tagNames.push(x?.value)
                                        })
                                    }
                                    setItem({...item, ['tags']: tagNames})
                                }}
                            />
                            <Input
                                value={newTag}
                                placeholder="Enter a New Tag"
                                onChange={({ detail }) => {
                                    if(validateTag(detail.value)){
                                        setNewTag(detail.value)
                                    } 
                                }}
                                onKeyDown={( {detail} ) => {
                                    if(detail.key === 'Enter'){
                                        //console.log(detail.key)
                                        let tagNames = structuredClone(item['tags']);
                                        if (newTag != "" && !item?.['tags'].includes(newTag)) {
                                            tagNames.push(newTag)
                                            setNewTag("")
                                        }
                                        setItem({...item, ['tags']: tagNames})
                                        
                                    }
                                    
                                }}
                            >
                            
                            </Input>
                            <Button
                                onClick={() => {
                                    let tagNames = structuredClone(item['tags']);
                                    if (newTag != "" && !item?.['tags'].includes(newTag)) {
                                        tagNames.push(newTag)
                                        setNewTag("")
                                    }
                                    setItem({...item, ['tags']: tagNames})
                                    
                                    }
                                }
                            >
                                Add New Tag
                            </Button>
                        </SpaceBetween>
                            

                    </FormField>
                    <FormField label="Architecture Diagram" description=".png, .jpeg, .jpg" errorText={diagramFileErrorText}>
                        <input 
                            disabled={isSubmitDisabled}
                            type='file' 
                            onChange={(e) => {
                                setIsSubmitDisabled(true)
                                let uuid = item['id']
                                let fileName = e.target.files[0].name
                                if(fileName.endsWith(".png") || fileName.endsWith(".jpeg") || fileName.endsWith(".jpg")){
                                    setDiagramFileErrorText("")
                                    console.log(fileName)
                                    setDiagramFile(e.target.files[0])
                                    getUploadUrl(uuid, fileName).then(
                                    (result) => {
                                        console.log(result)
                                        let url = result['url']
                                        let fields = result['fields']
                                        uploadFile(url, fields, e.target.files[0] ).then(
                                            (result) => {
                                                setIsSubmitDisabled(false)
                                                //console.log(result)
                                                let s3Path = s3Prefix + item['id'] + "/" + fileName
                                                setItem({...item, ['architecture-diagram']: s3Path  })
                                            },
                                            (error) => {
                                                setIsSubmitDisabled(false)
                                                console.log(error)
                                            }
                                        )
                                        
                                    },
                                    (error) => {
                                        console.log(error)
                                    }
                                )

                                } else {
                                    setDiagramFileErrorText("Invalid File Type")
                                    e.target.value = null;
                                    setIsSubmitDisabled(false)
                                }
                                
                            }}
                        
                        />

                    </FormField>
                    <FormField label="Architecture Source File" description=".pptx, .drawio" errorText={sourceFileErrorText}>
                    <input 
                            disabled={isSubmitDisabled}
                            type='file' 
                            onChange={(e) => {
                                setIsSubmitDisabled(true)
                                let uuid = item['id']
                                let fileName = e.target.files[0].name
                                if(fileName.endsWith(".drawio") || fileName.endsWith(".ppt") || fileName.endsWith(".pptx")){
                                    //console.log(fileName)
                                    setSourceFileErrorText("")
                                    setDiagramFile(e.target.files[0])
                                    getUploadUrl(uuid, fileName).then(
                                        (result) => {
                                            //console.log(result)
                                            let url = result['url']
                                            let fields = result['fields']
                                            uploadFile(url, fields, e.target.files[0] ).then(
                                                (result) => {
                                                    setIsSubmitDisabled(false)
                                                    let s3Path = s3Prefix + item['id'] + "/" + fileName
                                                    //console.log(result)
                                                    //console.log(s3Path)
                                                    setItem({...item, ['architecture-source']: s3Path  })
                                                },
                                                (error) => {
                                                    setIsSubmitDisabled(false)
                                                    console.log(error)
                                                }
                                            )
                                            
                                        },
                                        (error) => {
                                            console.log(error)
                                        }
                                    )
                                } else {
                                    setSourceFileErrorText("Invalid File Type")
                                    e.target.value = null;
                                    setIsSubmitDisabled(false)
                                }
                            }}
                        
                        />

                    </FormField>
                    <FormField label="Resource Links" description="Add Links to External Resources" errorText={resourceLinksErrorText}>
                    <AttributeEditor
                        onAddButtonClick={() => {
                            //console.log('addingItem')
                            setItem(prevItem => ({
                                ...prevItem,
                                ['resource-links']: [...prevItem['resource-links'], {"name": "", "link": ""}]
                            }));
                            //console.log(item['resource-links'])
                        }}
                        onRemoveButtonClick={({
                            detail: { itemIndex }
                        }) => {
                            const tmpItems = [...item['resource-links']];
                            tmpItems.splice(itemIndex, 1);
                            setItem({...item, ['resource-links']: tmpItems});
                        }}
                        items={item['resource-links']}
                        addButtonText="Add New Link"
                        removeButtonText='Remove Link'
                        definition={[
                            {
                            label: "Name",
                            control: (i, itemIndex) => (
                                <Input
                                value={i.name}
                                placeholder="Enter key"
                                onChange={({ detail }) => {
                                    updateLinkName(itemIndex, detail.value)
                                    
                                }}
                                />
                            )
                            },
                            {
                            label: "Link",
                            control: (i, itemIndex) => (
                                <Input
                                value={i.link}
                                placeholder="Enter value"
                                onChange={({ detail }) => {
                                    updateLinkValue(itemIndex, detail.value)
                                    
                                }}
                                />
                            )
                            }
                        ]}
                        empty="No Links Added"
                        />
                    </FormField>
                </SpaceBetween>
                    
                </Container>
            </SpaceBetween>
                
          </Form>
          
            


        </div>
    )

}

export default EditItem;