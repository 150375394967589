import './ItemPage.css'

import { ContentLayout, Container, Button, TextContent, Box, ColumnLayout } from "@cloudscape-design/components";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";

import Comments from '../components/Comments';
import LikeButton from "../components/LikeButton";

import { useNavigate, Link } from 'react-router-dom';

import { useParams, useSearchParams } from 'react-router-dom';
import { useState, useEffect} from 'react';
import { getItem, getDiagram, getSource, deleteItem } from "../utils/API";
import constants from "../utils/Constants";


let ItemPage = (props) => {
    let alias = sessionStorage.getItem("alias");

    const navigate = useNavigate();
    const [isOwner, setIsOwner ] = useState(false)

    let { id, createdDate } = useParams(); //
    const [searchParams] = useSearchParams();
    //console.log(id)
    //console.log(createdDate)

    const [item, setItem] = useState({
        "title": "EKS WebServer",
        "description": "This is a description"
    })

    const [selectedImage, setSelectedImage] = useState(null);

    const openModal = (imageUrl ) => {
        setSelectedImage(imageUrl);
      };
    
      const closeModal = () => {
        setSelectedImage(null);
      };


    useEffect(() =>{
        getItem(id, createdDate).then(
           (result) => {
               //console.log(result)
               setItem(result)
               //console.log(alias)
               if(result?.['author'] === alias){
                setIsOwner(true)
               }
           },
           (error) => {
               console.log(error)
           }
       ) 
    }, [])

    const ValueWithLabel = ({ label, children }) => (
        <div>
          <Box variant="awsui-key-label">{label}</Box>
          <div>{children}</div>
        </div>
      );
      let download = (url, filename) => {
        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        })
        .catch(console.error);
      }

      let ResourceLinks = ( resourceLinks ) =>{

    
        return (
            <ul>
                {resourceLinks?.map((linkObj, index) => (
                    <li key={index}>
                        <a href={linkObj.link} target="_blank">{linkObj.name}</a>
                    </li>
                ))}
            </ul>
        );
    }

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: "pointer"
    };

    const imageStyle = {
        maxWidth: '1000px',
        height: 'auto'
    };
      

    return (
        <div>
            
            <SpaceBetween size="m">
            <Container>
            <Button iconName="arrow-left" variant="icon"
                onClick={() => {
                    navigate('/')
                }}
            ></Button>

                <SpaceBetween size="m">
                    <Header
                        variant='h1'
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <LikeButton 
                                    count={item?.['likes']?.length}
                                    icon={item?.['likes']?.includes(props.alias)? "thumbs-up-filled" : "thumbs-up"}
                                    disabled={item?.['likes']?.includes(props.alias)}
                                    id={item?.id}
                                    alias={props.alias}
                                    createdDate={item?.['createdDate']}
                                />
                            { isOwner ? <Button
                                onClick={() => {
                                    console.log(item)
                                    navigate("/new", {state: item})
                                
                                }}
                            >Edit</Button> : null
                            }
                                
                            { isOwner ? <Button
                                onClick={() => {
                                    deleteItem(id, createdDate).then(
                                        (result) => {
                                            //console.log(result)
                                            navigate('/')
                                        },
                                        (error) => {
                                            console.log(error)
                                            navigate('/')
                                        }
                                    )
                                }}
                            >Delete</Button> : null
                            
                            }
                            </SpaceBetween>
                        }
                    >
                        {item?.['title']} 
                    </Header>
                    </SpaceBetween>
                    <SpaceBetween size='m'>
                    <Container>
                    <ColumnLayout columns={3} variant="text-grid">
                        <ValueWithLabel label="Author(s)">
                            <div>
                                <a href={'https://phonetool.amazon.com/users/' + item?.author} target="_blank">{item?.author}</a>        
                            </div>
                        </ValueWithLabel>
                        <ValueWithLabel label="Services">{item?.services?.join(', ')}</ValueWithLabel>
                        <ValueWithLabel label="Tags">{item?.tags?.join(', ')}</ValueWithLabel>
                    </ColumnLayout>
                    </Container>
                
                
                
                    <Container
                        header={
                            <Header
                                variant="h3"
                            >
                                Description
                            </Header>
                        }
                    >
                        <TextContent>
                            <p>{item?.description}</p>
                        </TextContent>
                    </Container>
                    <Container
                        header={
                            <Header 
                                variant='h3'
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button iconName="download"
                                            onClick={() => {
                                                getDiagram(id, createdDate).then(
                                                    (result) => {
                                                        console.log('here')
                                                        console.log(result)  
                                                        //console.log(result)
                                                        const link = document.createElement('a');
                                                        //link.download = "export";
                                                        link.href = result;
                                                        link.target = "_blank"
                                                        link.click(); 
                                                    },
                                                    (error) => {
                                                        console.log(error)
                                                    }
                                                ) 
                                            }}
                                        >
                                            Download Diagram
                                        </Button>
                                        <Button 
                                            iconName="download"
                                            onClick={() => {
                                                getSource(id, createdDate).then(
                                                    (result) => {
                                                        //console.log(result)  
                                                        //console.log(result)
                                                        const link = document.createElement('a');
                                                        //link.download = "export";
                                                        link.href = result;
                                                        link.target = "_blank"
                                                        link.click(); 
                                                    },
                                                    (error) => {
                                                        console.log(error)
                                                    }
                                                ) 
                                            }}
                                        >
                                            Download Source
                                        </Button>
                                    </SpaceBetween>
                                }
                            >
                                Architecture
                            </Header>
                        }
                        
                    >
                        <div style={containerStyle}>
                            <img 
                                src={item?.['architecture-diagram']?.replace('s3://inspire-static-content/', constants.staticContentUlrPrefix)} 
                                alt="Architecture" className="archDiagram"
                                onClick={() => {
                                    //console.log(item?.['architecture-diagram']?.replace('s3://inspire-static-content/', constants.staticContentUlrPrefix))
                                    openModal(item?.['architecture-diagram']?.replace('s3://inspire-static-content/', constants.staticContentUlrPrefix))}
                                }
                                style={imageStyle}
                            /> 
                        </div>

                    </Container>
                

                    <Container
                        header={
                            <Header 
                            variant='h3'
                            >
                                Resource Links
                            </Header>
                        }
                    >
                        {
                            ResourceLinks(item?.['resource-links'])
                        }



                    </Container>
                </SpaceBetween> 
            </Container>
            <Comments data={item?.comments} id={item?.['id']} createdDate={item?.['createdDate']} alias={props.alias}/>
            </SpaceBetween>
            {selectedImage && (
                <div className="modal" onClick={closeModal}>
                <img src={selectedImage} alt="Selected" className="modal-content" />
                <span className="close-button">&times;</span>
                </div>
            )}
        </div>
    )
}

export default ItemPage;