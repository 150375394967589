

let services = [ 
      "Athena" ,
      "AWS Clean Rooms" ,
      "CloudSearch" ,
      "AWS Data Exchange" ,
      "Data Pipeline" ,
      "Amazon DataZone" ,
      "EMR" ,
      "Amazon FinSpace" ,
      "AWS Glue" ,
      "AWS Glue DataBrew" ,
      "Kinesis" ,
      "AWS Lake Formation" ,
      "MSK" ,
      "Amazon OpenSearch Service" ,
      "QuickSight" ,
      "Amazon Redshift",
      "Amazon AppFlow" ,
      "Amazon EventBridge" ,
      "Managed Apache Airflow" ,
      "Amazon MQ" ,
      "Simple Notification Service" ,
      "Simple Queue Service" ,
      "Step Functions" ,
      "SWF",
      "AWS Application Cost Profiler" ,
      "AWS Billing Conductor" ,
      "AWS Budgets" ,
      "AWS Cost Explorer" ,
      "AWS Marketplace Subscriptions",
      "Amazon Managed Blockchain", 
      "Alexa for Business" ,
      "Amazon Chime" ,
      "Amazon Chime SDK" ,
      "Amazon Connect" ,
      "Amazon Honeycode" ,
      "Amazon Pinpoint" ,
      "Amazon Simple Email Service" ,
      "AWS Supply Chain" ,
      "AWS Wikr" ,
      "Amazon Workdocs" ,
      "Amazon WorkMail", 
      "AWS App Runner" ,
      "Batch" ,
      "EC2" ,
      "EC2 Image Builder" ,
      "Elastic Beanstalk" ,
      "Lambda" ,
      "Lightsail" ,
      "AWS Outposts" ,
      "Serverless Application Repository" ,
      "AWS SimSpace Weaver",
      "Elastic Container Registry" ,
      "Elastic Container Service" ,
      "Elastic Kubernetes Service" ,
      "Red Hat OpenShift Service on AWS",
      "Activate for Startups" ,
      "AWS IQ" ,
      "Managed Services" ,
      "Support",
      "Amazon DocumentDB" ,
      "DynamoDB" ,
      "ElastiCache" ,
      "Amazon Keyspaces" ,
      "Amazon MemoryDB for Redis" ,
      "Neptune" ,
      "Amazon QLDB" ,
      "RDS" ,
      "Amazon Timestream",
      "AWS AppConfig" ,
      "Application Composer" ,
      "Cloud9" ,
      "CloudShell" ,
      "CodeArtifact" ,
      "CodeBuild" ,
      "Amazon CodeCatalyst" ,
      "CodeCommit" ,
      "CodeDeploy" ,
      "CodePipeline" ,
      "CodeStar" ,
      "Amazon CodeWhisperer" ,
      "AWS FIS" ,
      "X-Ray",
      "End User Computing",
      "AppStream 2.0" ,
      "WorkSpaces" ,
      "Workspaces Web",
      "AWS Amplify" ,
      "AWS AppSync" ,
      "Device Farm" ,
      "Amazon Location Service",
      "Amazon GameLift" ,
      "Amazon GameSparks",
      "FreeRTOS" ,
      "IoT 1-Click" ,
      "IoT Analytics" ,
      "IoT Core" ,
      "IoT Device Defender" ,
      "IoT Device Management" ,
      "IoT Events" ,
      "AWS IoT FleetWise" ,
      "IoT Greengrass" ,
      "IoT RoboRunner" ,
      "IoT SiteWise" ,
      "IoT TwinMaker",
      "Amazon Augmented AI" ,
      "Amazon Bedrock" ,
      "Amazon CodeGuru" ,
      "Amazon Comprehend" ,
      "Amazon Comprehend Medical" ,
      "AWS DeepComposer" ,
      "AWS DeepLens" ,
      "AWS DeepRacer" ,
      "Amazon DevOps Guru" ,
      "Amazon Forecast" ,
      "Amazon Fraud Detector" ,
      "Amazon HealthLake" ,
      "Amazon Kendra" ,
      "Amazon Lex" ,
      "Amazon Lookout for Equipment" ,
      "Amazon Lookout for Metrics" ,
      "Amazon Lookout for Vision" ,
      "Amazon Monitron" ,
      "Amazon Omics" ,
      "AWS Panorama" ,
      "Amazon Personalize" ,
      "Amazon Polly" ,
      "Amazon Rekognition" ,
      "Amazon SageMaker" ,
      "Amazon Textract" ,
      "Amazon Transcribe" ,
      "Amazon Translate",
      "AWS Auto Scaling" ,
      "CDK" ,
      "AWS Chatbot" ,
      "CloudFormation" ,
      "CloudTrail" ,
      "CloudWatch" ,
      "AWS Compute Optimizer" ,
      "Config" ,
      "Control Tower" ,
      "Amazon Grafana" ,
      "Incident Manager" ,
      "Launch Wizard" ,
      "AWS License Manager" ,
      "OpsWorks" ,
      "AWS Organizations" ,
      "Amazon Prometheus" ,
      "AWS Proton" ,
      "AWS Resilience Hub" ,
      "AWS Reource Explorer" ,
      "Resource Groups & Tag Editor" ,
      "Service Catalog" ,
      "Systems Manager" ,
      "AWS Telco Network Builder" ,
      "Trusted Advisor" ,
      "AWS User Notifications" ,
      "AWS Well-Architected Tool" ,
      "AWS Health Dashboard",
      "Elastic Transcoder" ,
      "Elemental Appliances & Software" ,
      "Amazon Interactive Video Service" ,
      "Kinesis Video Streams" ,
      "MediaConnect" ,
      "MediaConvert" ,
      "MediaLive" ,
      "MediaPackage" ,
      "MediaStore" ,
      "MediaTailor" ,
      "Nimble Studio",
      "Application Discovery Service" ,
      "AWS Application Migration Service" ,
      "Database Migration Service" ,
      "DataSync" ,
      "AWS Mainframe Modernization" ,
      "AWS Migration Hub" ,
      "AWS Snow Family" ,
      "AWS Transfer Family",
      "API Gateway" ,
      "AWS App Mesh" ,
      "AWS Cloud Map" ,
      "CloudFront" ,
      "Direct Connect" ,
      "Global Accelerator" ,
      "AWS Private 5G" ,
      "Route 53" ,
      "Route 53 Application Recover Controller" ,
      "VPC",
      "Amazon Braket",
      "AWS RoboMaker",
      "Ground Station",
      "AWS Artifact" ,
      "AWS Audit Manager" ,
      "Certificate Manager" ,
      "CloudHSM" ,
      "Cognito" ,
      "Detective" ,
      "Directory Service" ,
      "AWS Firewall Manager" ,
      "GuardDuty" ,
      "IAM" ,
      "IAM Identity Center" ,
      "Amazon Inspector" ,
      "Key Management Service" ,
      "Amazon Macie" ,
      "AWS Private Certificate Authority" ,
      "Resource Access Manager" ,
      "Secrets Manager" ,
      "Security Hub" ,
      "Security Lake" ,
      "AWS Signer" ,
      "Amazon Verified Permissions" ,
      "WAF & Shield",
      "AWS Backup" ,
      "EFS" ,
      "AWS Elastic Disaster Recovery" ,
      "FSx" ,
      "Fargate",
      "S3" ,
      "S3 Glacier" ,
      "Storage Gateway" 
]

let getServices = () => {
    let servicesList = [];
    services.sort((a,b) => a.localeCompare(b))
    services.forEach((x, i) => {
        servicesList.push({label: x, value: x})
    })
    return servicesList;
}

let getServicesArray = () => {
    services.sort((a,b) => a.localeCompare(b))
    return services;
}

export  {getServices, getServicesArray}